.navigation {
  position: fixed;
  left: 20px;
  top: 80%;
  /* bottom:-100px; */
  transform: translateY(-50%) translateX(-100px);
  /* background-color: rgba(0, 0, 0, 0.596); */
  padding:  10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 20px;
  border-radius: 50px;
  opacity: 0;
  animation: fade-nav-in 1s ease 1 1s forwards;
}

@keyframes fade-nav-in {
  to {
    transform: translateY(-50%) translateX(0);
    opacity: 1;
  }
}


.navigation a {
  position: relative;
  /* background-color: rgba(0, 0, 0, 0.397); */
  /* border-radius: 50%; */
}

.icon {
  font-size: 40px;
  border-radius: 50%;
  padding: 5px;
  transition: fill 0.5s ease-in-out;
  fill: rgb(209, 217, 252);
}

.navigation .icon .active-nav {
  fill: rgb(88, 117, 248);
  border: 2px solid rgb(88, 117, 248);
  fill: #64ffa5;
  border:2px solid #64ffda
}

.navigation .icon:hover {
  fill: rgba(88, 117, 248, 0.151);
  fill: #64ffda;
  border: 1px solid rgba(88, 117, 248, 0.192);
  transition: fill 0.5s ease-in-out;
}

.navigation .icon .active-nav:hover {
  fill: rgb(88, 117, 248);
  border: 2px solid rgb(88, 117, 248);
  fill: #64ffda;
  border:1px solid #64ffda;
  transition: fill 2s ease-in-out;
}



/* @media (max-width: 650px) {
  .navigation {
    position: fixed;
    left: -2px;
    top: 80%;
    transform: translateY(-50%) translateX(-100px);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 15px;
    border-radius: 50px;
    opacity: 0;
    animation: fade-nav-in 1s ease 1 1s forwards;
  }

  .navigation a {
    position: relative;
  }
  
  .icon {
    height: 33px;
    border-radius: 50%;
    transition: fill 0.5s ease-in-out;
    fill: rgb(209, 217, 252);
  } 
} */


@media (max-width: 650px) {
  .navigation {
    display:none;
  }
}












/* 
@media (max-width: 720px) {
  .navigation {
    position: fixed;
    z-index: 1000;
    backdrop-filter: blur(30px);
    left: 50%;
    transform: translateX(-50%) translateY(100px);
    opacity: 0.1;
    top: 83%;
    background-color: rgba(0, 0, 0, 0.596);
    padding: 5px 30px;

    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 15px;
    border-radius: 80px;
    animation: fade-nav-bottom 1s ease forwards;
  }

  @keyframes fade-nav-bottom {
    to {
      transform: translateX(-50%) translateY(0);
      opacity: 1;
    }
  } */

  /* .navigation a {
    position: relative; */
    /* background-color: rgba(0, 0, 0, 0.397); */
    /* border-radius: 50%;
  } */

  /* .icon {
    font-size: 40px;
    border-radius: 50%;
    padding: 5px;
    transition: fill 0.1s ease;
    fill: rgb(209, 217, 252);
  }

  .bar {
    display: none;
  }
} */





/* 
@media (max-width: 400px) {
  .navigation {
    position: fixed;
    z-index: 1000; */
    /* backdrop-filter: blur(30px); */
    /* left: 50%;
    transform: translateX(-50%) translateY(100px);
    opacity: 0;
    top: 86%; */
    /* background-color: rgba(0, 0, 0, 0.596); */
    /* padding: 12px 20px;
    max-width: 99%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 20px;
    border-radius: 50px;
    animation: fade-nav-bottom 1s ease forwards;
  } */

/* 
  @keyframes fade-nav-bottom {
    to {
      transform: translateX(-50%) translateY(0);
      opacity: 1;
    }
  }

  .navigation a {
    position: relative; */
    /* background-color: rgba(0, 0, 0, 0.397); */
    /* border-radius: 50%;
  }

  .icon {
    font-size: 35px;
    border-radius: 50%;
    padding: 8px;
    transition: fill 0.1s ease;
    fill: rgb(209, 217, 252);
  }
} */
