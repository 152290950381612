.contact-container {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  min-height: 80vh;
  margin-top: 100px;
  padding: 50px;
}

.contact-container .contact-links {
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-top: 40px;
}

.contact {
  background-color: rgba(0, 0, 0, 0.322);
  border-radius: 20px;
  transition: all 0.3s ease, transform 0.2s ease;
}

/* .contact:hover {
  fill: #64ffda;
  border: 2px solid rgba(88, 117, 248, 0.192);
  transition: fill 0.1s ease-in-out;
} */

.contact .contactIcon {
  position: relative;
  font-size: 40px;
  padding: 8px;
}

.contact .contactIcon:hover{
  fill: #64ffda;
  border: 2px solid rgba(88, 117, 248, 0.192);
  transition: fill 0.1s ease-in-out;
  border-radius: 20px;

}

