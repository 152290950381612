
.logo{
  animation: rotate-0 1s linear 1s infinite;
}
@keyframes rotate-0 {
  to {
    transform: rotate(360deg);
  }
} 


.logo2 {
  animation: spin 1s linear 1.1s infinite
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}




.logo3 {
  animation: spin2 1s linear 1.2s infinite
}

@keyframes spin2 {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}







@media (max-width: 640px) {

  .brand span {
    font-size: 20px;
  
  }
}