
@media (max-width: 800px) {
  #home {
    width: 90%;
  }
}


@media (min-width: 768px) {
  .about{
    padding-bottom: 0;
  }
  .imgg{
    padding-bottom: 0;
  }
  #home {
    width: 95%;
  }
}


@media (max-width: 768px) {
  .webText {
    display: hidden;
  }
  .img {
    padding-bottom: 0;  
  }
  .about{
    width: 60%;
  }
  .imgg{
    width: 80%;
  }
}



@media (max-width: 500px) {
  .about {
    padding-left: 0;
    padding-right: 0;
    width:90%
  }

  .imgg{
    padding-left: 0;
    padding-right: 0;
    width:100%
  }
}


@media (min-width: 980px) {
  .picHover{
    --color: #eee344; /* the border color */
      --border: 4px;   /* the border thickness*/
      --offset: 20px;   /* control the offset*/
      --gap: 10px;       /* the gap on hover */
      
      --_c: var(--color) var(--border), #0000 0 calc(100% - var(--border)),var(--color) 0;
      --_o: calc(3*var(--offset));
      padding: 
        calc(var(--gap) + var(--border))
        calc(var(--gap) + var(--border) + var(--offset))
        calc(var(--gap) + var(--border) + var(--offset))
        calc(var(--gap) + var(--border));
      background: 
        linear-gradient(      var(--_c)) var(--_o) var(--_o), 
        linear-gradient(90deg,var(--_c)) var(--_o) var(--_o);
      background-size: calc(100% - var(--_o)) calc(100% - var(--_o));
      background-repeat: no-repeat;
      filter: grayscale(.4);
      transition: .8s;
      cursor: pointer;
    }
    .picHover:hover {
      background-position: 0px 0px;
      background-size: calc(100% - var(--offset)) calc(100% - var(--offset));
      filter: grayscale(0);
    }
}



.boxx {
  box-shadow: -1px -2px 24px 1px rgba(0,0,0,0.67);
-webkit-box-shadow: -1px -2px 24px 1px rgba(0,0,0,0.67);
-moz-box-shadow: -1px -2px 24px 1px rgba(0,0,0,0.67);

}


.picShadow {
  /* filter: drop-shadow(0.8rem 0.2rem 0.2rem rgba(0, 0, 0, 0.6)); */
  filter: drop-shadow(13px -13px 18px #000);

}


.border-animation{
  background: linear-gradient(0deg, #0d1f30, #0a192f);
}

.border-animation:before, .border-animation:after {
	content: '';
	position: absolute;
	left: -2px;
	top: -2px;
	background: linear-gradient(45deg, #0a192f, #0a192f, 
		#64ffda, #64ffda, #0a192f, #0a192f, #64ffda, #64ffda );
	background-size: 400%;
	width: calc(100% + 4px);
	height: calc(100% + 4px);
  /* border-radius: 5px; */
	z-index: -1;
	animation: steam 35s linear infinite;
}

@keyframes steam {
	0% {
		background-position: 0 0;
	}
	50% {
		background-position: 400% 0;
	}
	100% {
		background-position: 0 0;
	}
}

.border-animation:after {
	filter: blur(2px);
}



/* */
/* .picHover {
  border: 5px solid;
  border-image: repeating-linear-gradient(135deg,#F8CA00 0 10px,  0 30px) 8;
  -webkit-mask: 
    conic-gradient(from 360deg at top 8px right 8px, #0000 90deg,#000 0)
    var(--_i,200%) 0  /200% var(--_i,8px) border-box no-repeat,
    conic-gradient(at bottom 8px left  8px,  #0000 90deg,#000 0)
    0   var(--_i,200%)/var(--_i,8px) 200% border-box no-repeat,
    linear-gradient(#000 0 0) padding-box no-repeat;
  transition: .7s, -webkit-mask-size .5s .5s;

}

.picHover:hover {
  --_i: 100%;
  color: #2a6dd1;
  transition: 0.7s, -webkit-mask-position .5s .5s;

} */


