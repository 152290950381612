
/* Google fonts */
@import url('https://fonts.googleapis.com/css2?family=Cormorant:wght@400;500;600;700&display=swap');

@import url('https://fonts.googleapis.com/css2?family=Cormorant:wght@400;500;600;700&family=Montserrat:wght@400;500;600;700;800&display=swap');

@import url("https://fonts.googleapis.com/css?family=Source+Sans+Pro");


/* animate js */
@import 'animate.css';





@tailwind base;
@tailwind components;
@tailwind utilities;

/* for smooth scroll transition */
html {
  scroll-behavior: smooth;
}

/* If we want to apply tailwind on to the body of the app */
@layer base {
  body {
    /* @apply bg-[#0a192f] overflow-auto */
    @apply bg-[#09172b] overflow-auto

    /* @apply bg-[#150532] overflow-auto */
  }
}


@layer components {
  .hoverText {
    @apply text-[#808040] hover:text-[#eee344] cursor-pointer ease-in-out duration-300
  }
}